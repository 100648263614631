/****************************************
pricing
****************************************/
.pricing {

    .switch {
        margin: 4rem auto 5rem auto;
        width: max-content;
        .form-switch {
            width: max-content;
        }
        label {
            font-size: 1.6rem;
            font-weight: 500;
            color: #fff;
            cursor: pointer;
        }
        input {
            width: 3.2rem;
            height: 2.1rem;
            float: none;
            margin: 0 1.1rem;
            border: none;
            box-shadow: none;
            border: .2rem solid #fff;
            cursor: pointer;
            &, &:focus, &:active {
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
            }
            &:checked {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23999dfd'/%3e%3c/svg%3e");
                background-color: #fff;
            }
        }
    }

    .swiper-container {
        overflow: visible;
    }

    &-single {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5.8rem 4rem;

        @include border-radius(2rem);

        .plan {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
        }

        .price {
            font-size: 3rem;
            font-weight: 500;
            color: $font-grey;
            text-align: center;
            margin: 2.2rem 0 6rem 0;

            width: 100%;

            overflow: hidden;
            position: relative;

            .month {
                position: absolute;
                top: -100%;
                left: 50%;
                transform: translateX(-50%);
                @include animateIt;
            }
            .year {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                @include animateIt;
            }
            sup {
                font-size: 1.4rem;
                @media (max-width: 991px) {
                    font-size: 1.6rem;
                }
            }

            &-month {
                .month {
                    top: 0;
                }
                .year {
                    top: 100%;
                }
            }
        }

        .button {
            width: 100%;
            &-content {
                margin: 0 auto;
            }
        }

        ul {
            list-style: none;
            margin: 6rem 0 7.5rem 0;
            li {
                font-size: 1.6rem;
                font-weight: 500;
                color: $font-grey;
                &:nth-child(n+2) {
                    margin-top: 1.5rem;
                }
                &::before {
                    content: '\f00d';
                    font-family: 'Line Awesome Free';
                    font-size: 1.4rem;
                    color: $color-red;
                    display: inline-block;
                    font-weight: 900;
                    width: auto;
                    height: auto;
                    position: relative;
                    margin-right: 1rem;

                    @media (max-width: 991px) {
                        font-size: 1.6rem;
                    }
                }
                &.available {
                    &::before {
                        content: '\f00c';
                        color: $color-green;
                    }
                }
            }
        }

        h6 {
            font-size: 1.2rem;
            text-align: center;
            color: $font-grey;
        }

        &.basic {
            @include drop-shadow($color: var(--gradient-pink-1-rgba-2), $yOffset: 20px);

            .plan {
                color: var(--gradient-pink-1);
            }
        }
        &.standard {
            @include drop-shadow($color: var(--gradient-purple-1-rgba-2), $yOffset: 20px);

            .plan {
                color: var(--gradient-purple-1);
            }
        }
        &.premium {
            @include drop-shadow($color: var(--gradient-blue-1-rgba-2), $yOffset: 20px);

            .plan {
                color: var(--gradient-blue-1);
            }
        }
    }
}

/****************************************
pricing section
****************************************/
.pricing-section {
    &-wrapper {
        position: relative;
        padding: 15rem 0 0 0;

        .background-pattern {
            width: 100%;
            height: 56%;
        }

        @media (max-width: 991px) {
            padding: 5rem 0 0 0;
        }
    }

    .section-heading {
        position: relative;
        z-index: 2;
    }

    .pricing {
        position: relative;
        z-index: 2;
    }
}